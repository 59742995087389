<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        Aniline,
        <chemical-latex content="C6H5NH2," />
        is a weak base that is commonly used in the preparation of precursors for polyurethane
        production. The
        <stemble-latex content="$\text{K}_\text{b}$" />
        value of aniline is
        <stemble-latex content="$4.3\times 10^{-10}.$" />
        If you titrate a
        <number-value :value="volSoln" unit="\text{mL}" />
        solution of
        <number-value :value="concA" unit="\text{M}" />
        aniline, with
        <number-value :value="concHCl" unit="\text{M}" />
        <chemical-latex content="HCl," />
        answer each of the questions below:
      </p>

      <p class="mb-2">a) How many moles of aniline are present in the original solution?</p>

      <calculation-input
        v-model="inputs.molA"
        class="mb-5"
        prepend-text="$\ce{C6H5NH2}:$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) What volume of HCl (in litres) must be added in order to reach the equivalence point,
        <stemble-latex content="$\text{V}_\text{eq}?$" />
      </p>

      <calculation-input
        v-model="inputs.Veq"
        class="mb-5"
        prepend-text="$\text{V}_\text{eq}:$"
        append-text="$\text{L}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        c) What is the
        <stemble-latex content="$\text{pH}$" />
        at the equivalence point?
      </p>

      <calculation-input
        v-model="inputs.pH"
        prepend-text="$\text{pH:}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question166',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        molA: null,
        Veq: null,
        pH: null,
      },
    };
  },
  computed: {
    volSoln() {
      return this.taskState.getValueBySymbol('volSoln').content;
    },
    concA() {
      return this.taskState.getValueBySymbol('concA').content;
    },
    concHCl() {
      return this.taskState.getValueBySymbol('concHCl').content;
    },
  },
};
</script>
